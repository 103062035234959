import { Link } from "gatsby";
import * as React from "react";

import Tags from "./Tags";

export interface Props {
  post: {
    id: string;
    fields: {
      slug: string;
    };
    frontmatter: {
      title: string;
      author: string;
      description: string;
      lang: string;
      templateKey: string;
      published: string;
      publishedDate: string;
      tags: string[];
    };
  };
}

const PostPreview: React.SFC<Props> = ({ post }) => (
  <li key={post.id} lang={post.frontmatter.lang}>
    <Link to={post.fields.slug}>[{post.frontmatter.lang}] {post.frontmatter.title}</Link> - <em><time dateTime={post.frontmatter.publishedDate}>{post.frontmatter.published}</time></em> - by <em>{post.frontmatter.author}</em>
    <br />
    <Tags tags={post.frontmatter.tags} /> <small>{post.frontmatter.description}</small>
  </li>
);

export default PostPreview;
